<template>
    <dashboard-layout>
        <v-container>
            <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="pages"
                :items-per-page="15"
                class="v-data-table--links elevation-1"
                @click:row="handleClickRow"
            >
                <template v-slot:item.created_at="{ item }">
                    {{ item.created_at ? $date(item.created_at).format('MMM DD, YYYY HH:mm') : '—' }}
                </template>
                <template v-slot:item.updated_at="{ item }">
                    {{ item.updated_at ? $date(item.updated_at).format('MMM DD, YYYY HH:mm') : '—' }}
                </template>
            </v-data-table>
        </v-container>
    </dashboard-layout>
</template>

<script>
import Block from "@/models/Block";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "blocks-index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            pages: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'slug',
                },
                {text: 'Key', value: 'key'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Updated at', value: 'updated_at'},
            ],
        }
    },
    methods: {
        handleClickRow(page) {
            this.$router.push({name: 'admin.blocks.show', params: {id: page.id}})
        }
    },
    async mounted() {
        this.loading = true
        this.pages = await Block.get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
